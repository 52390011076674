<template>
    <div class="wrapperBox">
        <div class="leftBox">
            <img src="../assets/img/logo.png" alt="" v-if="activeName == '1'" />
            <img src="../assets/img/logo1.png" alt="" v-else />
        </div>
        <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            :class="activeName == '1' ? 'wrapperTab' : 'nextTab'"
        >
            <el-tab-pane label="企业介绍" name="1">
                <div class="contentBox">
                    <div class="videoBox">
                        <!-- <video src="../assets/img/banner.mp4" autoplay controls muted id="videoId"></video> -->
                        <img src="../assets/img/banner.png" alt="" />
                        <div class="showBox">
                            <div class="showContent">
                                <img
                                    src="../assets/img/bannerc.png"
                                    alt=""
                                    class="showTop"
                                />
                                <div class="fontBox">
                                    <p>搭建以产品全生命周期为中心的数字化服务体系</p>
                                </div>
                                <img src="../assets/img/down.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="homeBox">
                        <img src="../assets/img/home1.png" alt="" />
                        <img src="../assets/img/home2.png" alt="" />
                        <img src="../assets/img/home3.png" alt="" />
                        <img src="../assets/img/home4.png" alt="" />
                    </div>
                    
                </div>
            </el-tab-pane>
            <el-tab-pane label="CTOS" name="2">
                <div class="sectionBox">
                    <div class="sectionCenter">
                        <img src="../assets/img/page1.png" alt="" />
                    </div>
                    <div class="sectionTab">
                        <el-tabs v-model="activeIndex" class="childrenTab">
                            <el-tab-pane label="聚焦" name="1">
                                <div class="tabBox">
                                    <img src="../assets/img/1.png" alt="" />
                                    <img src="../assets/img/tab1.png" alt="" />
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="智能" name="2">
                                <div class="tabBox">
                                    <img src="../assets/img/2.png" alt="" />
                                    <img src="../assets/img/tab2.png" alt="" />
                                    <img src="../assets/img/tab3.png" alt="" />
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="协作与整合" name="3"
                                ><div class="tabBox">
                                    <img src="../assets/img/3.png" alt="" />
                                    <img src="../assets/img/tab4.png" alt="" />
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="透明与全景" name="4"
                                ><div class="tabBox">
                                    <img src="../assets/img/4.png" alt="" />
                                    <img src="../assets/img/tab5.png" alt="" />
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="真实世界数据解决方案" name="3">
                <div class="threeBox">
                    <img src="../assets/img/page2.png" alt="" />
                    <img src="../assets/img/t1.png" alt="" />
                    <img src="../assets/img/t2.png" alt="" />
                    <img src="../assets/img/t3.png" alt="" />
                </div>
            </el-tab-pane>
            <el-tab-pane label="ANGEL-ASPECT" name="4">

            </el-tab-pane>
        </el-tabs>
        <div class="homeFooter">
            <div class="homeImg">
                <img src="../assets/img/logo1.png" alt="" />
            </div>
            <ul>
                <li>
                    <p>电话：010-62986638</p>
                </li>
                <li>
                    <p>邮箱：fengjie.ji@wise-med.com</p>
                </li>
                <li>
                    <p>邮编：100070</p>
                </li>
                <li>
                    <p>地址：北京市丰台区诺德中心三期16号楼9层</p>
                    <p>京ICP备2023004151号</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeName: "1",
            activeIndex: "1",
        };
    },
    mounted() {
        document.getElementById("#videoId").play();
    },
    methods: {
        handleClick(){
            if(this.activeName == 4){
                window.location.href = 'http://aspect.wise-med.com/';
                return 
            }
        }
    },
};
</script>
<style lang="less" scoped>
.wrapperBox {
    position: relative;
    .homeFooter {
        padding: 60px 0 40px 0;
        background: #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .homeImg {
            width: 65%;
            margin-bottom: 80px;
            img {
                width: 200px;
            }
        }

        ul {
            width: 65%;
            border-bottom: 2px solid #cccccc;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    font-size: 14px;
                    color: #666666;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                p:last-child {
                    margin-bottom: 14px;
                }
            }
        }
    }
    .leftBox {
        position: absolute;
        top: 14px;
        left: 18%;
        z-index: 55;
        img {
            width: 200px;
        }
    }
    .wrapperTab {
        /deep/.el-tabs__header {
            position: relative;
            margin: 0;
            background: #000;
            // background: #F2F7FF;
        }
        /deep/ .el-tabs__nav-wrap::after {
            height: 0;
        }
        /deep/.el-tabs__item {
            height: 60px;
            line-height: 60px;
            color: #fff;
        }
        /deep/ .el-tabs__nav {
            margin-left: 63%;
        }
        /deep/ .el-tabs__active-bar {
            background-color: #fff;
        }
    }
    .nextTab {
        /deep/.el-tabs__header {
            position: relative;
            margin: 0;
            background: #f2f7ff;
        }
        /deep/ .el-tabs__nav-wrap::after {
            height: 0;
        }
        /deep/.el-tabs__item {
            height: 60px;
            line-height: 60px;
            color: #1a4fa1;
        }
        /deep/ .el-tabs__nav {
            margin-left: 63%;
        }
        /deep/ .el-tabs__active-bar {
            background-color: #1a4fa1;
        }
    }
    .contentBox {
        .videoBox {
            width: 100%;
            position: relative;
            // video {
            //     width: 100%;
            //     height: 100%;
            //     object-fit: fill;
            // }
            img {
                width: 100%;
                height: 600px;
            }
            .showBox {
                width: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                z-index: 2;
                .showContent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .showTop {
                    margin-top: 64px;
                    width: 200px;
                    height: 95px;
                    margin-bottom: 18px;
                }
                p {
                    font-size: 64px;
                    line-height: 96px;
                    text-align: center;
                    color: #fff;
                }
                img {
                    width: 60px;
                    height: 60px;
                    margin-top: 121px;
                }
            }
        }
        .homeBox {
            background: #f5f6f9;
            img {
                width: 100%;
            }
        }
    }
    .sectionBox {
        .sectionCenter {
            background: #1a50a1;
            img {
                width: 100%;
            }
        }
        .tabBox {
            img {
                width: 100%;
            }
        }
        .childrenTab {
            /deep/.el-tabs__header {
                background: #1a50a1;
            }
            /deep/ .el-tabs__item {
                height: 60px;
                line-height: 60px;
            }
            /deep/ .el-tabs__nav {
                margin-left: 18%;
            }
            /deep/ .el-tabs__item {
                padding: 0;
                width: 100px;
                text-align: center;
                margin-right: 10px;
                color: #fff;
            }
            /deep/ .el-tabs__item.is-active {
                background: #28b9c2;
                color: #fff;
                border-bottom: 0;
            }
            /deep/ .el-tabs__active-bar {
                background-color: transparent !important;
            }
            /deep/ .el-tabs__nav-wrap::after {
                position: static !important;
            }
            /deep/ .el-tabs__item:hover {
                background: #28b9c2;
                color: #fff;
            }
            /deep/ .el-tabs__header {
                margin: 0;
            }
            /deep/ .el-tabs__item {
                height: 60px;
                line-height: 60px;
            }
        }
    }
    .threeBox {
        img {
            width: 100%;
        }
    }
}
</style>